// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-article-tsx": () => import("/Users/jackedgson/Documents/turboclicks/turboclicks/website/src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-pages-404-tsx": () => import("/Users/jackedgson/Documents/turboclicks/turboclicks/website/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/Users/jackedgson/Documents/turboclicks/turboclicks/website/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("/Users/jackedgson/Documents/turboclicks/turboclicks/website/src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("/Users/jackedgson/Documents/turboclicks/turboclicks/website/src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-contact-tsx": () => import("/Users/jackedgson/Documents/turboclicks/turboclicks/website/src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faqs-tsx": () => import("/Users/jackedgson/Documents/turboclicks/turboclicks/website/src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-free-audit-tsx": () => import("/Users/jackedgson/Documents/turboclicks/turboclicks/website/src/pages/free-audit.tsx" /* webpackChunkName: "component---src-pages-free-audit-tsx" */),
  "component---src-pages-index-tsx": () => import("/Users/jackedgson/Documents/turboclicks/turboclicks/website/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("/Users/jackedgson/Documents/turboclicks/turboclicks/website/src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-services-brisbane-website-hosting-tsx": () => import("/Users/jackedgson/Documents/turboclicks/turboclicks/website/src/pages/services/brisbane-website-hosting.tsx" /* webpackChunkName: "component---src-pages-services-brisbane-website-hosting-tsx" */),
  "component---src-pages-services-business-application-development-tsx": () => import("/Users/jackedgson/Documents/turboclicks/turboclicks/website/src/pages/services/business-application-development.tsx" /* webpackChunkName: "component---src-pages-services-business-application-development-tsx" */),
  "component---src-pages-services-google-ppc-advertising-tsx": () => import("/Users/jackedgson/Documents/turboclicks/turboclicks/website/src/pages/services/google-ppc-advertising.tsx" /* webpackChunkName: "component---src-pages-services-google-ppc-advertising-tsx" */),
  "component---src-pages-services-online-remarketing-australia-tsx": () => import("/Users/jackedgson/Documents/turboclicks/turboclicks/website/src/pages/services/online-remarketing-australia.tsx" /* webpackChunkName: "component---src-pages-services-online-remarketing-australia-tsx" */),
  "component---src-pages-services-search-engine-optimisation-tsx": () => import("/Users/jackedgson/Documents/turboclicks/turboclicks/website/src/pages/services/search-engine-optimisation.tsx" /* webpackChunkName: "component---src-pages-services-search-engine-optimisation-tsx" */),
  "component---src-pages-services-social-media-marketing-tsx": () => import("/Users/jackedgson/Documents/turboclicks/turboclicks/website/src/pages/services/social-media-marketing.tsx" /* webpackChunkName: "component---src-pages-services-social-media-marketing-tsx" */),
  "component---src-pages-services-website-development-brisbane-tsx": () => import("/Users/jackedgson/Documents/turboclicks/turboclicks/website/src/pages/services/website-development-brisbane.tsx" /* webpackChunkName: "component---src-pages-services-website-development-brisbane-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("/Users/jackedgson/Documents/turboclicks/turboclicks/website/src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */)
}

exports.data = () => import("/Users/jackedgson/Documents/turboclicks/turboclicks/website/.cache/data.json")

